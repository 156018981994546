// import React, { Suspense } from 'react';
// import ReactDOM from 'react-dom';

// import { CssBaseline } from '@material-ui/core';
// import { MuiThemeProvider } from '@material-ui/core/styles';

// import App from './App';
// import AppStateProvider, { useAppState } from './state';
// import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
// import ErrorDialog from './components/ErrorDialog/ErrorDialog';
// import LoginPage from './components/LoginPage/LoginPage';
// import PrivateRoute from './components/PrivateRoute/PrivateRoute';
// import theme from './theme';
// import './types';
// import { ChatProvider } from './components/ChatProvider';
// import { VideoProvider } from './components/VideoProvider';
// import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
// import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';

// Bugsnag.start({
//   apiKey: '87ef7cf468343151a2bacee1e95b1544',
//   plugins: [new BugsnagPluginReact()],
// });

// const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment;

// const VideoApp = () => {
//   const { error, setError } = useAppState();
//   const connectionOptions = useConnectionOptions();

//   return (
//     <VideoProvider options={connectionOptions} onError={setError}>
//       <ErrorDialog dismissError={() => setError(null)} error={error} />
//       <ChatProvider>
//         <App />
//       </ChatProvider>
//     </VideoProvider>
//   );
// };

// const AppTwoComponent = React.lazy(() => import('srt/PatientView'));

// ReactDOM.render(
//   // <ErrorBoundary>
//   //   <MuiThemeProvider theme={theme}>
//   //     <CssBaseline />
//   //     <UnsupportedBrowserWarning>
//   //       <Router>
//   //         <AppStateProvider>
//   //           <Switch>
//   //             <PrivateRoute path="/:sessionId?/:username?/:role?">
//   //               <VideoApp />
//   //             </PrivateRoute>
//   //             <PrivateRoute path="/room/:URLRoomName">
//   //               <VideoApp />
//   //             </PrivateRoute>
//   //             <Route path="/login">
//   //               <LoginPage />
//   //             </Route>
//   //             <Redirect to="/" />
//   //           </Switch>
//   //         </AppStateProvider>
//   //       </Router>
//   //     </UnsupportedBrowserWarning>
//   //   </MuiThemeProvider>
//   // </ErrorBoundary>,

//   <Suspense fallback={<div>Loading...</div>}>
//     <AppTwoComponent />
//   </Suspense>,

//   document.getElementById('root')
// );

import('./bootstrap.js');

// export {};
